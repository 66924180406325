<template>
  <div class="cover-design">
    <b-row>
      <b-col md="4" class="preview-zone" @dblclick.shift="inputCover">
        <div class="sticky-top" style="top: 20px;">
          <cover :book="coverParams" :class="{zoom: showPics}"/>
        </div>
      </b-col>

      <b-col cols="12" md>
        <!-- PC 修改书名作者 -->
        <b-row v-show="!showPics">
          <b-col>
            <b-form-group>
              <b slot="label">
                编辑书名
                <small>（最多20字）</small>
              </b>
              <input @change="onSubmit" class="form-control" id="title" maxlength="20"
                     placeholder="输入您想要的书名" v-model.lazy="title">
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b slot="label">
                编辑作者
                <small>（最多15字）</small>
              </b>
              <input @change="onSubmit" class="form-control" id="author" maxlength="15"
                     placeholder="输入作者名" v-model.lazy="author">
            </b-form-group>
          </b-col>
        </b-row>

        <div class="cover-styles" v-show="!showPics">
          <b-row align-v="center" class="mb-3">
            <b-col>
              <h6 class="mb-1">更换封面</h6>
              <div class="small text-muted">
                <span class="d-sm-none">左右滑动查看更多</span>
                <span class="d-none d-sm-inline">点击选择封面模板</span>
              </div>
            </b-col>
            <b-col cols="auto">
              <b-btn @click="adjusting = true" v-if="cover.pic && cover.editable">
                <fa icon="arrows"/>
                微调图片
              </b-btn>
              <b-btn @click="goGallery()" v-if="cover.editable" variant="success">
                <fa icon="image"/>
                更换图片
              </b-btn>
              <div class="small text-danger" v-else>此封面不支持自定义图片</div>
            </b-col>
          </b-row>

          <grid class="cover-list" scrollable>
            <grid-item :key="item.codeName" v-for="item in coversAvailable">
              <square :selected="item.codeName === cover.codeName" :src="coverUrl(item)"
                      @click="selectCover(item)" :height="book.type" shadow>
                <div class="style-tag" v-if="item.editable">
                  <fa icon="image"/>
                </div>
                <div class="deprecation-tag" v-if="item.deprecated">
                  <fa icon="calendar-times"/>
                </div>
              </square>
            </grid-item>
          </grid>
        </div>

        <div class="gallery-container" v-show="showPics">
          <div class="alert alert-primary" v-if="cover.codeName === 'cover-0'">
            <fa icon="exclamation-triangle"/>
            您正在使用自制封面，此款封面<b>不包含</b>标题作者等信息，需要您
            <b>自己制作</b>
            封面后再上传，建议图片尺寸为 2480x3508，色彩空间为 RGB。
          </div>

          <header>
            <b-row align-v="center" class="mb-3">
              <b-col>
                <h6 class="mb-1">更换图片</h6>
                <div class="small text-muted">
                  <span class="d-none d-sm-inline">上下滚动选择图片</span>
                  <span class="d-sm-none">左右滑动选择图片</span>
                </div>
              </b-col>
              <b-col cols="auto">
                <b-btn @click="back">取消</b-btn>
                <b-btn @click="savePic" variant="success">保存图片</b-btn>
              </b-col>
            </b-row>
          </header>

          <grid class="gallery">
            <grid-item>
              <square :disabled="uploading" @click="uploadPic" border>
                <template v-if="uploading">
                  <fa class="icon" icon="spinner" spin/>
                  上传中
                </template>
                <template v-else>
                  <fa class="icon" icon="cloud-upload"/>
                  上传图片
                </template>
              </square>
            </grid-item>
            <grid-item v-if="!isCustom">
              <square :disabled="!cover.pic" @click="adjusting = true" border>
                <fa class="icon" icon="arrows"/>
                微调图片
              </square>
            </grid-item>
            <grid-item v-else-if="!isMobile">
              <square @click="downloadSample" border>
                <fa icon="cloud-download"/>
                下载示例
              </square>
            </grid-item>
            <grid-item>
              <square :disabled="!cover.pic" @click="deletePic" border>
                <fa class="icon" icon="file-image"/>
                恢复默认
              </square>
            </grid-item>
            <template v-if="!isCustom">
              <grid-item :key="'local' + pic" v-for="pic in localPics">
                <square :selected="pic === cover.pic" :src="pic + '!320'"
                        @click="selectPic(pic)" shrink>
                  <b-link @click.stop="removeUploadPic(pic)" class="remove-pic-btn"
                          v-if="pic !== cover.pic">
                    <fa icon="times"/>
                  </b-link>
                </square>
              </grid-item>

              <grid-item :key="'gallery' + pic" v-for="pic in gallery">
                <square :selected="pic === cover.pic" :src="pic + '!320'" @click="selectPic(pic)"
                        no-loader shrink></square>
              </grid-item>
            </template>
          </grid>

          <div class="mt-3 text-right text-muted d-none d-sm-block">
            图库图片均来自
            <b-link href="https://unsplash.com" target="_blank">
              <img src="https://img.xinshu.me/upload/5acaf423b25f6ba9592be5d81a74f189"
                   alt="Unsplash" style="height: 1em; vertical-align: middle;">
            </b-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <move-area v-model="cover" @save="onCoverAdjusted" v-if="adjusting"/>
  </div>
</template>

<script>
import { attempt, includes, pick, trim, uniq } from 'lodash'

import routeData from '@/mixins/route-data'
import inherits from '@/mixins/inherits'
import MoveArea from '@/components/MoveArea'
import Upload from '@/models/upload'

export default {
  name: 'coverDesign',
  title: '编辑封面',
  mixins: [
    routeData('coverTemplates'),
    inherits(['book', 'bookParams'])
  ],
  components: {MoveArea},
  data() {
    return {
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 20
      },

      gallery: [],

      adjusting: false,
      showPics: false,

      title: '',
      author: '',
      cover: {},

      picPrev: '',
      codeNamePrev: '',
      localPics: this.$ls.get('uploadPics') || [],

      uploadModel: new Upload()
    }
  },
  computed: {
    errors() {
      const errors = []
      if (!trim(this.title)) {
        errors.push({
          message: '请填写书名',
          type: 'title'
        })
      }
      if (!trim(this.author)) {
        errors.push({
          message: '请填写作者名',
          type: 'author'
        })
      }
      return errors
    },
    uploading() {
      return this.uploadModel.uploading
    },
    coverParams() {
      return {
        cover: this.cover,
        title: this.title,
        author: this.author,
        fromDate: this.book.fromDate,
        toDate: this.book.toDate,
        type: this.book.type
      }
    },
    coversAvailable() {
      const category = this.book.bookType === 'blogbook' ? 'blogbook' : 'book'
      return this.coverTemplates.filter(item => {
        const results = [item.type === category]
        if (item.typesets && item.typesets.length) {
          results.push(item.typesets.includes(this.book.type))
        }
        return results.every(Boolean)
      })
    },
    isCustom() {
      return this.cover.codeName === 'cover-0'
    }
  },
  watch: {
    localPics(val) {
      this.$ls.set('uploadPics', uniq(val))
    }
  },
  created() {
    this.picPrev = this.cover.pic || ''
    this.coverOriginal = {...this.book.cover}

    this.title = this.book.title
    this.author = this.book.author
    this.cover = {...this.book.cover}

    if (this.cover.codeName === 'cover-0' && !this.cover.pic) {
      this.showPics = true
    }
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        this.scrollIntoView('.cover-styles .selected')
      })

      if (!this.coversAvailable.find(i => i.codeName === this.cover.codeName)) {
        this.coverTemplates.unshift({
          codeName: this.cover.codeName,
          editable: !!this.cover.editable,
          deprecated: true,
          type: 'book'
        })
      }
    },
    coverUrl(cover) {
      return this.getCoverUrl({cover, title: '那些年的朋友圈', author: '心书'}, 320)
    },
    onCoverAdjusted(result) {
      this.cover = {...this.cover, x: result.x, y: result.y, rotate: result.rotate}
      this.adjusting = false
      this.onSubmit()
    },

    async goGallery() {
      this.showPics = true

      if (!this.gallery.length) {
        this.gallery = await this.$ajax.fetchGallery()
      }

      const pic = this.cover.pic || ''

      // 封面图片为上传图片
      if (pic && !includes(this.localPics, pic) && !includes(this.gallery, pic) &&
        pic.includes('upload')) {
        this.localPics.unshift(pic)
      }
    },

    inputCover() {
      const codeName = prompt('输入封面代号')
      if (!codeName) {
        return
      }
      this.cover.codeName = codeName
      this.cover.editable = true
      this.onSubmit()
    },
    async selectCover(cover) {
      if (cover.codeName === this.cover.codeName) {
        return
      }
      this.cover.codeName = cover.codeName
      this.cover.editable = cover.editable
      this.onSubmit()
      setTimeout(() => {
        this.scrollIntoView('.cover-item.active')
      })
      if (cover.codeName === 'cover-0') {
        this.showPics = true
        this.cover.pic = ''
      }
    },
    downloadSample() {
      const link = document.createElement('a')
      link.href = 'https://canvas.xinshu.me/generate/cover-0?dl=1'
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    selectPic(pic) {
      this.$set(this.cover, 'pic', pic)
      this.onSubmit()
      setTimeout(() => {
        this.scrollIntoView('.image.active')
      })
    },

    async uploadPic() {
      const result = await this.uploadModel.upload()
      if (result) {
        this.$set(this.cover, 'pic', result.url)
        this.localPics.unshift(result.url)
        this.onSubmit()
      }
    },
    savePic() {
      this.showPics = false
      if (this.isCustom && !this.cover.pic) {
        this.cover.codeName = this.coverOriginal.codeName
        this.cover.pic = this.coverOriginal.pic
      }
      this.picPrev = this.cover.pic
    },
    back() {
      this.showPics = false
      if (this.isCustom && !this.cover.pic) {
        this.cover.codeName = this.coverOriginal.codeName
        this.cover.pic = this.coverOriginal.pic
      } else {
        this.cover.pic = this.picPrev
      }
    },

    async onSubmit() {
      if (this.errors.length) {
        this.$alert.warn(this.errors[0].message)
        attempt(() => this.$el.querySelector('#' + this.errors[0].type).focus())
        return
      }

      const data = {
        title: this.title,
        author: this.author,
        cover: pick(this.cover, ['codeName', 'pic', 'editable', 'x', 'y', 'rotate'])
      }

      await this.$parent.saveDesign(data)
    },
    // 恢复默认图片
    deletePic() {
      this.cover.pic = ''
      this.onSubmit()
    },
    removeUploadPic(pic) {
      this.localPics.splice(this.localPics.indexOf(pic), 1)
      this.$alert.success('图片已删除')
    }
  }
}
</script>

<style lang="scss" scoped>
$font-family-icon: 'Font Awesome 5 Pro';

$top: 300px;
$bottom: 0;

.bottom .content {
  padding: .75em $grid-gutter-width-base / 2;
}

.square {
  border: 2px solid transparent;
}

.square.selected {
  border: 2px solid $primary;
}

.cover-list {
  .grid-item {
    min-width: 7.25em;

    .style-tag {
      position: absolute;
      top: 8px;
      left: 0;
      width: 2.5em;
      line-height: 1.8;
      border-radius: 0 100px 100px 0;
      z-index: 0;
      background-color: $success;
      color: #fff;
      text-align: center;
      font-size: 12px;
    }

    .deprecation-tag {
      position: absolute;
      top: 8px;
      right: 0;
      width: 2.5em;
      line-height: 1.8;
      border-radius: 100px 0 0 100px;
      z-index: 0;
      background-color: $warning;
      color: #fff;
      text-align: center;
      font-size: 12px;
    }
  }
}

.gallery-container {
  .is-iphone-x & {
    padding-bottom: calc(1rem + #{$safe-area-bottom});
  }

  @include media-breakpoint-down(sm) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 21;
    padding: 1rem;
    background-color: #fff;
    border-top: 1px solid $hr-border-color;
  }
}

.gallery {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-down(sm) {
    flex-wrap: nowrap;
  }

  .grid-item {
    min-width: 6em;
    cursor: pointer;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .remove-pic-btn {
      position: absolute;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, .6);
      color: #fff;
      width: 2em;
      height: 2em;
      line-height: 2em;
      text-align: center;
      padding: 0 6px;
      z-index: 10;
    }
  }
}

.check {
  @include check-mark();
}
</style>
