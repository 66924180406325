<template>
  <div class="insert-design">
    <b-row>
      <b-col class="preview-zone" md="4">
        <div class="sticky-top" style="top: 20px;">
        <template v-if="selected === 'blank'">
          <div class="swiper-container pb-3">
            <square height="a5">
              <p>
                <fa icon="ban" size="3x"/>
              </p>
              内页中将不会出现插页
            </square>
          </div>
        </template>
        <template v-else>
          <swiper :options="options">
            <swiper-slide v-for="i in 12" :key="i">
              <square height="a5" :src="getInsertUrl(selected, i)"/>
              <div class="mt-3 text-center"><b>{{i}}月</b></div>
            </swiper-slide>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
          </swiper>
          <div class="mt-1 text-center">
            左右滑动查看更多
          </div>
        </template>
        </div>
      </b-col>
      <b-col>
        <p>
          <b>月份插页</b>
        </p>
        <grid class="inserts" cols="4" sm="5" scrollable>
          <template v-if="loading">
            <grid-item :key="i" v-for="i in 13">
              <square height="a5" shadow skeleton/>
              <div class="my-2 text-center">
                <ske width="4em"/>
              </div>
            </grid-item>
          </template>
          <template v-else>
            <grid-item :key="item.codeName" v-for="item in inserts">
              <square :selected="selected === item.codeName"
                      :src="getInsertUrl(item.codeName)"
                      @click="selectInsert(item)" height="a5" shadow/>
              <div class="my-2 text-center">{{item.name}}</div>
            </grid-item>
          </template>
          <grid-item>
            <square :selected="selected === 'blank'"
                    @click="selectInsert({codeName: 'blank'})" height="a5" shadow>
              <fa icon="ban" class="text-muted" size="3x"/>
            </square>
            <div class="my-2 text-center">无插页</div>
          </grid-item>
        </grid>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'
import routeData from '@/mixins/route-data'

export default {
  name: 'insertDesign',
  title: '选择插页',
  mixins: [
    routeData('inserts'),
    inherits('book'),
    inherits('bookParams'),
    require('@/mixins/swiper').default
  ],
  data() {
    return {
      selected: '',
      showPics: false,
      options: {
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        lazy: true
      },

      previewingImage: '',
      clickable: true,
      sortedInsertPages: [],
      sortedPageFooters: []
    }
  },
  computed: {
    firstMonth() {
      return this.$day(this.book.fromDate).format('YYYYMM')
    }
  },
  created() {
    this.selected = this.book?.style?.insertPage || 'season'
    if (this.selected === 'default') {
      this.selected = 'season'
    }
  },
  methods: {
    onLoad() {
      try {
        const offsetLeft = this.$el.querySelector('.selected').offsetLeft
        this.$el.querySelector('.grid').scrollLeft = offsetLeft - 8
      } catch (err) {
      }
    },

    getInsertUrl(codeName, month) {
      month = month || new Date().getMonth() + 1
      const year = new Date().getFullYear()
      return `https://canvas.xinshu.me/generate/insert-${codeName}.${month}?year=${year}&size=480`
    },

    async selectInsert(item) {
      this.selected = item.codeName
      const style = Object.assign({}, this.book.style, {insertPage: item.codeName})
      await this.$parent.saveDesign({style})
    }
  }
}
</script>

<style lang="scss" scoped>
.insert-design {
  .preview-zone {
    .swiper-container {
      padding-left: 8px;
      padding-right: 8px;
      user-select: none;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      max-width: 100% !important;

      .swiper-container {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }

  [data-code-name="default"] {
    background-color: #fff;
  }
}
</style>
