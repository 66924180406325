<template>
  <div class="copyright">
    <h2 class="page-title">编辑版权页</h2>

    <loading v-if="loading"/>
    <template v-else>
      <b-card>
        <copyright-page :book="bookInfo" editing :class="[active]"/>
      </b-card>

      <div>
        <p>
          <fa icon="info-circle"/>
          点击输入框时将会高亮对应的部分，仅建议专业用户修改本页信息
        </p>
        <b-row>
          <b-col>
            <b-form-group label="作品名">
              <b-input placeholder="作品名称" v-model="title" maxlength="20"
                       @focus="active = 'title'" @blur="active = ''"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="作者">
              <b-input placeholder="作者名" v-model="author" maxlength="20"
                       @focus="active = 'author'" @blur="active = ''"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="出品（选填）">
          <b-input placeholder="出品方（不超过 20 字）" v-model="copyright.publisher"
                   @focus="active = 'publisher'" @blur="active = ''"
                   maxlength="20"/>
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group label="责任编辑（选填）">
              <b-input placeholder="责编姓名" v-model="copyright.editor" maxlength="20"
                       @focus="active = 'editor'" @blur="active = ''"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="装帧设计（选填）">
              <b-input placeholder="设计师姓名" v-model="copyright.designer" maxlength="20"
                       @focus="active = 'designer'" @blur="active = ''"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm>
            <b-form-group label="出版发行（选填）">
              <b-input placeholder="发行公司名称" v-model="copyright.company" maxlength="20"
                       @focus="active = 'company'" @blur="active = ''"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="公司网址（选填）">
              <b-input placeholder="发行公司网址" v-model="copyright.link" maxlength="120"
                       @focus="active = 'link'" @blur="active = ''"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="公司电话（选填）">
              <b-input placeholder="发行公司电话" v-model="copyright.telephone" maxlength="120"
                       @focus="active = 'telephone'" @blur="active = ''"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="版次（选填，需按格式填写）">
          <b-input v-model="copyright.edition" placeholder="版次（默认以当前月份为准）"
                       @focus="active = 'edition'" @blur="active = ''"/>
        </b-form-group>
      </div>

      <b-btn class="mt-3" variant="primary" block @click="save">保存并返回</b-btn>
    </template>
  </div>
</template>

<script>
import CopyrightPage from '@/pages/Book/CopyrightPage'
import routeData from '@/mixins/route-data'

export default {
  name: 'copyrightDesign',
  mixins: [routeData('book', {query: {agent: true}})],
  components: {CopyrightPage},
  data() {
    return {
      active: '',
      title: '',
      author: '',
      copyright: {
        editor: '',
        designer: '',
        publisher: '',
        company: '',
        edition: '',
        link: '',
        telephone: ''
      }
    }
  },
  computed: {
    bookInfo() {
      const style = {
        ...this.book.style,
        copyright: this.copyright
      }

      return {
        ...this.book,
        title: this.title,
        author: this.author,
        style
      }
    }
  },
  methods: {
    async onLoad() {
      this.title = this.book.title
      this.author = this.book.author
      this.copyright = this.book.style.copyright || this.copyright
    },
    async save() {
      const data = Object.assign({}, {
        type: this.book.type,
        bookType: this.book.bookType,
        sourceId: this.book.sourceId,
        sourceType: this.book.sourceType,
        sourceTypeId: this.book.sourceTypeId,
        title: this.title,
        author: this.author,
        style: {...this.book.style, copyright: this.copyright}
      })

      const edition = this.copyright.edition
      if (edition && !/\d{4}年\d{1,2}月第\d{1,3}版/.test(edition)) {
        return this.$alert.error('请按格式填写版次')
      }

      if (data.bookType === 'blogbook') {
        await this.$ajax.updateBlogbook({bid: this.book.id, data})
      } else {
        await this.$req.post('/api/book/design', data)
      }

      this.$alert.success('版权页信息保存成功')
      this.$router.push('../#copyright')
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  margin-top: 0;
}

.copyright {
  max-width: 640px;
}

.copyright-page {
  font-size: 14px !important;
  padding: 0;

  &.title ::v-deep .title {
    background-color: yellow;
  }

  &.author ::v-deep .author {
    background-color: yellow;
  }

  &.publisher ::v-deep .publisher {
    background-color: yellow;
  }

  &.designer ::v-deep .designer {
    background-color: yellow;
  }

  &.editor ::v-deep .editor {
    background-color: yellow;
  }

  &.company ::v-deep .company {
    background-color: yellow;
  }

  &.link ::v-deep .link {
    background-color: yellow;
  }

  &.telephone ::v-deep .telephone {
    background-color: yellow;
  }

  &.edition ::v-deep .edition {
    background-color: yellow;
  }
}
</style>
