<template>
  <div class="design">
    <b-nav class="d-none d-md-flex pc-nav align-items-end mb-4">
      <b-nav-item :to="item.to" v-for="item in navs" :key="item.to" :active="item.active" replace>
        <fa :icon="item.icon" :fas="item.active"/>
        {{item.text}}
      </b-nav-item>
      <li class="right" style="max-width: 16em;">
        <b-button variant="primary" class="preview-btn" @click="goPreview" :disabled="saving">
          <template v-if="saving">
            <fa icon="spinner" spin/>
            保存中
          </template>
          <template v-else>
            <fa icon="book"/>
            保存并预览
          </template>
        </b-button>
      </li>
    </b-nav>

    <template v-if="loading">
      <b-row>
        <b-col md="4">
          <ske type="cover"></ske>
        </b-col>
        <b-col>
          <p>
            <ske width="4em"></ske>
          </p>
          <grid sm="5" cols="4">
            <grid-item v-for="i in 4" :key="i">
              <ske type="cover"/>
            </grid-item>
          </grid>
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <router-view/>

      <bottom-bar nav :items="navItems"/>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'
import bookUtils from '@/mixins/book-utils'
import { getBookId } from '@/models/book'

export default {
  name: 'design',
  title: '设计样式',
  mixins: [
    routeData('book', {relativeParam: 'bookId'}),
    bookUtils
  ],
  data() {
    return {
      saving: false,
      newBookId: null
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$ss.setItem('design.previous', this.$route.name)
    next()
  },
  created() {
    const previousRoute = this.$ss.getItem('design.previous') || 'book.design.cover'
    if (this.$route.name === 'book.design') {
      this.$ss.removeItem('design.previous')
      this.$router.replace({name: previousRoute})
    }
  },
  computed: {
    navs() {
      return this.navItems.filter(i => !i.isButton && i.show !== false)
    },
    navItems() {
      const prefix = `/books/${this.bookId}`
      return [
        {
          text: '封面',
          icon: 'book-alt',
          to: prefix + '/design/cover',
          active: this.$route.name === 'book.design.cover'
        },
        {
          text: '版式',
          icon: 'th',
          to: prefix + '/design/template',
          active: this.$route.name === 'book.design.template'
        },
        {
          text: '插页',
          icon: 'bookmark',
          to: prefix + '/design/insert',
          active: this.$route.name === 'book.design.insert',
          show: this.book.bookType !== 'blogbook'
        },
        {
          text: '背景',
          icon: 'image',
          to: prefix + '/design/background',
          active: this.$route.name === 'book.design.background'
        },
        {
          text: '保存并预览',
          icon: !this.saving ? 'book' : 'spinner fa-spin',
          isButton: true,
          onClick: () => this.goPreview(),
          variant: 'primary',
          disabled: this.saving
        }
      ]
    },
    coverParams() {
      return {
        cover: this.book.cover,
        title: this.book.title,
        author: this.book.author,
        fromDate: this.book.fromDate,
        toDate: this.book.toDate
      }
    }
  },
  methods: {
    async saveDesign(data) {
      if (data.style) {
        this.book.style = data.style
      }

      if (data.cover) {
        this.book.cover = data.cover
      }

      this.newBookId = null

      if (this.book.bookType === 'blogbook') {
        // 长文集分开处理
        if (data.type) {
          data.typesetType = data.type.toLowerCase()
          delete data.type
        }
        return this.$ajax.updateBlogbook({bid: this.book.id, data})
      }

      data = Object.assign({
        type: this.book.type,
        bookType: this.book.bookType,
        sourceId: this.book.sourceId,
        sourceType: this.book.sourceType,
        sourceTypeId: this.book.sourceTypeId
      }, data)

      const result = await this.$req.post('/api/book/design', data)
      if (result.bookId) {
        this.newBookId = result.bookId
      }
    },
    async goPreview() {
      // 希望用户能直接返回之前预览的位置
      const prevRoute = this.$store.state.routeHistory
        .find(r => r.name === 'book.inner' || r.name === 'book')
      if (prevRoute) {
        const {name, params, query} = prevRoute
        if (this.newBookId) {
          params.bookId = getBookId({...this.book, id: this.newBookId}) // 计算新排版的 bookId
        }
        return this.$router.push({name, params, query})
      }
      this.$router.push('/books/' + this.book.bookId)
    }
  }
}
</script>

<style scoped lang="scss">
.design {
  max-width: 1280px;

  .pc-nav {
    .nav-link {
      padding-top: .75em;
      padding-bottom: .75em;
      border-bottom: 2px solid $hr-border-color;

      &.active {
        border-bottom: 2px solid $primary;
      }

      &:not(.active) {
        color: $text-muted;

        img {
          opacity: .6;
          filter: grayscale(100%);
        }
      }

      @include media-breakpoint-down(sm) {
        padding-left: .5em;
        padding-right: .5em;
        .book-icon {
          height: 1.5em;
          margin-left: auto;
          margin-right: auto;
          display: block;
          margin-bottom: .25em;
        }
      }
    }

    .right {
      margin-left: auto;
    }
  }

  .mobile-preview {
    overflow: hidden;
    display: none;
    @include media-breakpoint-down(sm) {
      display: block;
    }

    .box {
      margin: 1em 0 2em;
      text-align: center;

      .box-content {
        margin: 1em auto;
      }

      .preview-cover {
        @include media-breakpoint-down(sm) {
          width: 200px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  ::v-deep .preview-zone {
    .square {
      box-shadow: $box-shadow;
    }

    @include media-breakpoint-down(sm) {
      margin-left: auto;
      margin-right: auto;
      max-width: 75%;
      margin-bottom: 1rem;
    }
  }
}
</style>
