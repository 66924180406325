<template>
  <div class="background-design">
    <b-row>
      <b-col md="4" class="preview-zone" v-if="!disabled">
        <div class="sticky-top" style="top: 20px;">
          <square :src="getFooterUrl(selected, 800)" shadow border mode="contain" height="a5"/>
          <div class="mt-3 text-center font-weight-bold">{{selectedTitle}}</div>
        </div>
      </b-col>
      <b-col cols="12" md>
        <empty icon="exclamation-triangle" style="background-color:#f2f2f2;" v-if="disabled">
          您选择的版式带有贴图及背景颜色
          <br>
          无法使用背景图案
          <br>
          <br>
          <b-link to="./template">前往更换版式</b-link>
        </empty>

        <template v-else>
          <p><b>内页背景图案</b></p>
          <div class="footers">
            <grid cols="4" sm="5" scrollable>
              <grid-item :key="i.codeName" v-for="i in footers">
                <square :selected="selected === i.codeName" :src="getFooterUrl(i.codeName)"
                        @click="selectFooter(i.codeName)" height="a5" shadow/>
                <div class="text-center my-2">{{i.name}}</div>
              </grid-item>
            </grid>
          </div>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'

export default {
  name: 'backgroundDesign',
  title: '选择背景',
  mixins: [
    inherits('book'),
    inherits('bookParams')
  ],
  data() {
    return {
      selected: '',
      footers: []
    }
  },
  async created() {
    this.footers = await this.$req.get(
      '/buckets/weixinshu:footers',
      {baseURL: 'https://ark.xinshu.me'}
    )
    setTimeout(() => {
      this.scrollIntoView('.footers .selected')
    })
  },
  computed: {
    selectedTitle() {
      return this.footers.find(i => i.codeName === this.selected)?.name || '默认空白'
    },
    disabled() {
      return this.book.type.includes('-child')
    }
  },
  mounted() {
    this.selected = this.book?.style?.pageFooter || 'default'
    this.$nextTick(() => {
      try {
        const offsetLeft = this.$el.querySelector('.selected').offsetLeft
        this.$el.querySelector('.grid').scrollLeft = offsetLeft - 8
      } catch (err) {
      }
    })
  },
  methods: {
    getFooterUrl(codeName, size = 320) {
      if (!codeName || codeName === 'default') {
        return ''
      }
      return `https://canvas.xinshu.me/generate/footer-${codeName}.left?size=` + size
    },
    async selectFooter(codeName) {
      this.selected = codeName
      const style = Object.assign({}, this.book.style, {pageFooter: codeName})
      await this.$parent.saveDesign({style})
    }
  }
}
</script>

<style lang="scss" scoped>
.background-design {
  .grid {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .disabled {
    pointer-events: none;
    position: relative;
    user-select: none;
  }
}
</style>
