<template>
  <div class="template-design">
    <b-row>
      <b-col md="4" class="mb-3">
        <div class="sticky-top" style="top: 20px;">
          <div class="preview-zone">
            <square :src="example" height="a5">
              <div class="ribbon">样例仅供参考</div>
            </square>
          </div>

          <div class="intro mt-3" v-if="activeTemplate">
            <h5>
              <b>{{activeTemplate.name}}（{{fontSizeName}}）</b>
            </h5>
            <div v-if="activeTemplate.desc" v-html="activeTemplate.desc"></div>
          </div>

          <b-row>
            <b-col v-if="canUseBabyTag">
              <b-btn block variant="success" v-b-modal="'baby'">
                <fa icon="calendar-alt"/>
                设置宝贝月龄标签
              </b-btn>
            </b-col>
            <b-col v-if="canEnableComment">
              <b-btn block variant="success" v-b-modal="'comment'">
                <fa icon="comments"/>
                设置评论
              </b-btn>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col cols="12" sm>
        <b-form-group label="A4 版式（210×285mm）" v-if="templatesA4.length">
          <grid class="template-list" cols="4">
            <grid-item v-for="item in templatesA4" :key="item.codeName"
                       @click="selectTemplate(item)">
              <square :selected="book.type === item.codeName"
                      :src="item.icon" border
                      height="140%"/>
              <div class="title py-2">
                <div>{{item.name}}</div>
                <b-badge variant="primary" v-if="item.tag">{{item.tag}}</b-badge>
                <b-badge variant="success" v-if="item.feature">{{item.feature}}</b-badge>
              </div>
            </grid-item>
          </grid>
        </b-form-group>

        <hr v-if="templatesA4.length">

        <b-form-group label="A5 版式（148×210mm）" v-if="templatesA5.length">
          <grid class="template-list scrollable" cols="5">
            <grid-item v-for="item in templatesA5" :key="item.codeName"
                       @click="selectTemplate(item)">
              <square :selected="book.type === item.codeName"
                      :src="item.icon" border
                      height="140%"/>
              <div class="title py-2">
                <div>{{item.name}}</div>
                <b-badge variant="primary" v-if="item.tag">{{item.tag}}</b-badge>
                <b-badge variant="success" v-if="item.feature">{{item.feature}}</b-badge>
              </div>
            </grid-item>
          </grid>
        </b-form-group>

        <hr>

        <b-form-group label="字体大小" class="adjust-font">
          <select-group v-model="fontSize"
                        @change="saveFontSize"
                        :options="fontSizeOptions"
                        v-if="canAdjustFontSize"/>
          <div v-else>当前排版不支持调整字号哦</div>
        </b-form-group>

        <template v-if="canAdjustAlignment">
          <divider>长文集内页调整</divider>
          <b-form-group>
            <b-row>
              <b-col sm="6">
                <b-form-group label="正文对齐方式（默认左对齐）">
                  <select-group v-model="textAlign" :options="alignOptions" @change="saveAlign"/>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group label="标题对齐方式（默认左对齐）">
                  <select-group v-model="titleAlign" :options="alignOptions" @change="saveAlign"/>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group label="是否显示文章日期">
                  <select-group v-model="dateEnabled" @change="saveAlign"
                                :options="[{value: true, title: '显示'}, {value: false, title: '不显示'}]"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" v-if="dateEnabled">
                <b-form-group label="日期对齐方式（默认右对齐）">
                  <select-group v-model="dateAlign" :options="alignOptions" @change="saveAlign"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
        </template>
      </b-col>
    </b-row>

    <b-modal id="comment" size="sm" title="评论内容" hide-footer>
      <p>
        <img src="https://img.xinshu.me/upload/c6a88210ec824ca1b755c3d0394bd9ae" alt=""
             v-webp>
      </p>

      <template v-if="!book.hasComments">
        <h5>当前排版可以显示评论哦</h5>
        <p>不过您还没有导入评论，使用微信书小助手<b>导出私密内容</b>后就可以开启啦。</p>

        <b-btn block to="/assistant" variant="primary">前往使用微信书小助手</b-btn>
      </template>

      <template v-else>
        <p>您可以点击下面的按钮切换评论内容显示，开启后作品内容中将会显示评论，也可通过「编辑内容」隐藏部分评论</p>
        <b-btn block :variant="comment.enable ? 'secondary' : 'primary'" @click="toggleComment">
          {{comment.enable ? '禁用' : '启用'}}评论
        </b-btn>
      </template>
    </b-modal>

    <b-modal id="baby" size="sm" title="月龄标签" ok-title="保存" @ok="saveBirthday">
      <p>设置宝贝姓名及生日后会替换内页默认标签为宝贝的月龄哦</p>
      <p>
        <img src="https://img.xinshu.me/upload/ac079df3a99049c6b697dd2b62917279" alt="">
      </p>
      <b-form-group label="宝贝昵称（最多3字）">
        <b-input placeholder="输入宝贝昵称" maxlength="3" v-model.trim="baby.nickname"/>
      </b-form-group>
      <b-form-group label="宝贝生日">
        <b-input type="date" v-model="baby.birthday"/>
      </b-form-group>

      <template v-slot:modal-footer="{ok}">
        <b-btn v-if="baby.enable" class="mr-auto" @click="disableBirthday">禁用月龄标签</b-btn>
        <b-btn variant="primary" @click="ok">{{baby.enable ? '保存' : '保存并启用'}}</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { get } from 'lodash'
import inherits from '@/mixins/inherits'

export default {
  name: 'templateDesign',
  title: '更改版式',
  mixins: [
    inherits(['book', 'saving', 'bookId', 'bookParams'])
  ],
  data() {
    return {
      templates: [
        {
          name: 'A5童趣版',
          codeName: 'A5-5-child',
          desc: '以A5瀑布流为基础，在空白位置增加卡通贴图，使排版充满童趣',
          icon: require('@/assets/images/templates/A5-5-child.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/aeb8e7e6ea0d48bf801c6b645516e877',
            big: 'https://img.xinshu.me/upload/dd45b4016e0d4884948a4507703fccc0',
            max: 'https://img.xinshu.me/upload/d3f27b927f3d4a26b775408f8db24188'
          }
        },
        {
          name: 'A5瀑布流',
          codeName: 'A5-5',
          desc: '灵动——时而饱满、时而留白——在固定页面里融合最「合适」的布局',
          icon: require('@/assets/images/templates/A5-5.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/fa1b59165a2b49f9b283a52db377c3c1',
            big: 'https://img.xinshu.me/upload/1a4d8ac13ac14297a45fc4b61e5ca4e2',
            max: 'https://img.xinshu.me/upload/953a271ca8f34318ac9575a272bada5f'
          }
        },
        {
          name: 'A5双栏',
          codeName: 'A5-6',
          desc: '排版分为两列，图文排列更加整齐，适合「强迫症患者」',
          feature: '可显示评论',
          icon: require('@/assets/images/templates/A5-6.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/bf655644651040a8895086b5c1e4e50c',
            big: 'https://img.xinshu.me/upload/3122308d69074e8c939735203c020fe8',
            max: 'https://img.xinshu.me/upload/7032f497416c45d29b553e648a94db14'
          }
        },
        {
          name: 'A5拾光',
          codeName: 'A5-4',
          desc: '留空较多，排版有呼吸，适合大量内容为每日心情类的作品',
          icon: require('@/assets/images/templates/A5-4.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/79d4be7103174d6e900229543a41898b'
          }
        },
        {
          name: 'A4双栏',
          codeName: 'A4-3',
          desc: '排版分为两列，图文排列更加整齐，适合「强迫症患者」',
          feature: '可显示月龄及评论',
          tag: 'NEW',
          icon: require('@/assets/images/templates/A5-6.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/resource/291146b5442d40678edf2c597688be44',
            big: 'https://img.xinshu.me/resource/3a958ad6b63149c4b134c482e8a12bcf',
            max: 'https://img.xinshu.me/resource/9e6e671627af4aae93edcc6aac77ec1f'
          }
        },
        {
          name: 'A4童趣版',
          codeName: 'A4-1-child',
          desc: '文如其名，以A4瀑布流为基础，在空白位置增加卡通贴图，使排版充满童趣',
          feature: '可显示月龄',
          icon: require('@/assets/images/templates/A4-1-child.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/ea46a95b6db84264a4ce056792d3a2a1',
            big: 'https://img.xinshu.me/upload/05bad220e1e54f6eb441342871a5d34f',
            max: 'https://img.xinshu.me/upload/c2e85dbc10e8404eadff7c5294179a6e'
          }
        },
        {
          name: 'A4瀑布流',
          codeName: 'A4-1',
          desc: '放大放大再放大，在A5瀑布流的基础上加大尺寸，使得每页能够排版更多内容',
          icon: require('@/assets/images/templates/A4-1.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/952d18513706483793b2b2acf6fbc925',
            big: 'https://img.xinshu.me/upload/a7d94683f39e4e029db419fad7e86524',
            max: 'https://img.xinshu.me/upload/ca1b2d1f19384dffac5b5c93db5a83c5'
          }
        },
        {
          name: 'A4杂志版',
          codeName: 'A4-2',
          desc: '以杂志排版为灵感，使用不对称双栏设计，既规整又不乏变化',
          icon: require('@/assets/images/templates/A4-2.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/161e4ac05a934174aef64c5c6bf2a90b',
            big: 'https://img.xinshu.me/upload/465c33b0f7644be49e2e39a1331c9b5a',
            max: 'https://img.xinshu.me/upload/678b6605a861469b94464a60c29f7dc5'
          }
        },
        {
          name: 'A4杂志版(旧版)',
          codeName: 'A4',
          desc: '旧版排版，已停止维护。建议更换为新版A4杂志版排版哦。',
          icon: require('@/assets/images/templates/A4.jpg'),
          disabled: true,
          pic: {
            normal: 'https://img.xinshu.me/upload/2a69e3a42dc1484f9501737645213bb8'
          }
        },
        {
          name: 'A5双栏(旧版)',
          codeName: 'A5-2',
          desc: '旧版排版，已停止维护。建议更换为新版双栏排版哦。',
          icon: require('@/assets/images/templates/A5-2.jpg'),
          disabled: true,
          pic: {
            normal: 'https://img.xinshu.me/upload/94254923f7a04e41bcca300eb8278bb2'
          }
        },
        {
          name: 'A5时光轴',
          codeName: 'A5-3',
          desc: '旧版排版，已停止维护。建议更换为其他排版哦。',
          icon: require('@/assets/images/templates/A5-3.jpg'),
          disabled: true,
          pic: {
            normal: 'https://img.xinshu.me/upload/a5a385808ed04d1c8014fcb9331e6306'
          }
        },
        {
          name: 'A5瀑布流 (旧版)',
          codeName: 'A5-1',
          desc: '旧版排版，已停止维护。建议更换为新版瀑布流排版哦。',
          icon: require('@/assets/images/templates/A5-1.jpg'),
          disabled: true,
          pic: {
            normal: 'https://img.xinshu.me/upload/110c62adc926408cb8ae82e49f8650fd'
          }
        }
      ],
      existingTemplates: [],
      comment: {
        enable: false
      },
      baby: {
        nickname: '',
        birthday: '',
        enable: false
      },
      fontSizeOptions: [
        {
          value: 'normal',
          title: '标准(五号)'
        },
        {
          value: 'big',
          title: '大号(小四)'
        },
        {
          value: 'max',
          title: '超大号(四号)'
        }
      ],
      fontSize: 'normal',
      alignOptions: [
        {
          value: 'left',
          title: '左对齐'
        },
        {
          value: 'center',
          title: '居中对齐'
        },
        {
          value: 'right',
          title: '右对齐'
        }
      ],

      textAlign: 'left',
      dateAlign: 'right',
      dateEnabled: false,
      titleAlign: 'left'
    }
  },
  computed: {
    example() {
      if (!this.book.type) {
        return ''
      }
      if (!this.canAdjustFontSize) {
        return this.activeTemplate.pic.normal
      }
      return this.activeTemplate.pic[this.fontSize]
    },
    canAdjustFontSize() {
      return Object.keys(this.activeTemplate.pic).length > 1
    },
    canAdjustAlignment() {
      return this.book.bookType === 'blogbook'
    },
    canEnableComment() {
      const {type, bookType, own} = this.book
      if (bookType !== 'wxbook') {
        return false
      }
      if (!own && !this.isAgent) {
        return
      }
      return type === 'A5-6' || type === 'A4-3'
    },
    canUseBabyTag() {
      const {type} = this.book
      return type === 'A4-1-child' || type === 'A4-3'
    },
    activeTemplate() {
      return this.templates.find(item => item.codeName === this.book.type)
    },
    templatesA4() {
      return this.templates
        .filter(i => i.codeName.includes('A4'))
        .filter(i => !i.disabled || this.existingTemplates.includes(i.codeName))
    },
    templatesA5() {
      return this.templates
        .filter(i => i.codeName.includes('A5'))
        .filter(i => !i.disabled || this.existingTemplates.includes(i.codeName))
    },
    fontSizeName() {
      const fonts = {normal: '标准字号', big: '大字号', max: '超大字号'}
      return fonts[this.fontSize] || '标准字号'
    }
  },
  async created() {
    this.fontSize = this.book?.style?.fontSize || 'normal'
    this.textAlign = this.book?.style?.textStyle || 'left'
    this.titleAlign = this.book?.style?.titleStyle || 'left'
    this.dateAlign = this.book?.style?.dateStyle || 'right'
    this.dateEnabled = /displayed/.test(this.book?.style?.dateStyle)

    this.dateAlign = (this.dateAlign.match(/left|center|right/) || [])[0]

    if (this.book?.style?.text?.fontSize === 16) {
      // 兼容旧的数据
      this.fontSize = 'big'
    }

    if (this.book?.style?.text?.fontSize === 18) {
      // 兼容旧的数据
      this.fontSize = 'max'
    }

    if (this.book.bookType === 'blogbook') {
      this.templates = [
        {
          name: 'A5长文集',
          codeName: 'A5-blog',
          desc: '纯粹的文字、图片经典排版。<br>适合写长文、博客等。',
          icon: require('@/assets/images/templates/A5-blog.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/dc6ae0298f5447ca84f75af16a1ab4d0',
            big: 'https://img.xinshu.me/upload/bdc1383447ff4967a9a2ef528808e107',
            max: 'https://img.xinshu.me/upload/e9471be909d041bf836404c80e45500f'
          }
        },
        {
          name: 'A4长文集',
          codeName: 'A4-blog',
          desc: '纯粹的文字、图片经典排版。<br>适合写长文、博客等。',
          icon: require('@/assets/images/templates/A4-blog.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/upload/dc6ae0298f5447ca84f75af16a1ab4d0',
            big: 'https://img.xinshu.me/upload/bdc1383447ff4967a9a2ef528808e107',
            max: 'https://img.xinshu.me/upload/e9471be909d041bf836404c80e45500f'
          }
        },
        {
          name: 'A4图片双栏 (不显示图注)',
          codeName: 'A4-2-blog',
          desc: '纯粹的文字、图片双栏排版（不显示图注）。<br>适合写长文、博客等。',
          icon: require('@/assets/images/templates/A4-2-blog.jpg'),
          pic: {
            normal: 'https://img.xinshu.me/resource/a0332e7329eb4d0a926758c28efc2b52',
            big: 'https://img.xinshu.me/resource/627156ebfa3648bfa23261e1b5a660de',
            max: 'https://img.xinshu.me/resource/52d244d7080541b8bdd451099378633c'
          }
        }
      ]
    }

    this.comment.enable = !!this.book.style.enableComment
    this.baby = Object.assign({}, this.baby, get(this.book, 'style.baby'))
    if (this.baby.birthday) {
      this.baby.birthday = this.$day(this.baby.birthday).format('YYYY-MM-DD')
    }

    if ((!this.book.own && !this.isAgent) || this.book.bookType !== 'wxbook') {
      const tpl = this.templates.find(i => i.codeName === 'A5-6' || i.codeName === 'A5-blog')
      if (tpl) {
        tpl.feature = ''
      }
    }

    if (this.book.bookType !== 'blogbook') {
      this.existingTemplates = await this.$req.get(
        `/api/book/${this.book.sourceId}/publish_types`,
        {
          params: {
            book_type: this.book.bookType,
            source_type_id: this.book.sourceTypeId
          }
        }
      )
    }

    this.$nextTick(() => {
      const activeEl = this.$el.querySelector('.square.selected')
      if (!activeEl || !('closest' in activeEl)) {
        return
      }
      activeEl.closest('.template-list').scrollLeft = activeEl.parentElement.offsetLeft
      activeEl.scrollIntoView()
    })
  },
  methods: {
    async toggleComment() {
      this.comment.enable = !this.comment.enable
      const style = Object.assign({}, this.book.style, {enableComment: this.comment.enable})
      await this.$parent.saveDesign({style})
      this.$alert.success('评论已' + (this.comment.enable ? '开启' : '关闭'))
    },
    async disableBirthday() {
      const baby = {...this.baby, enable: false}
      const style = Object.assign({}, this.book.style, {baby})
      await this.$parent.saveDesign({style})
      this.baby.enable = false
      this.$alert.success('宝贝月龄标签已禁用')
      this.$bvModal.hide('baby')
    },
    async saveBirthday(e) {
      if (!this.baby.birthday || !this.baby.nickname) {
        this.$alert.error('请指定宝贝的昵称及生日哦')
        e.preventDefault()
        return
      }
      const baby = {
        ...this.baby,
        enable: true,
        birthday: this.$day(this.baby.birthday).startOf('day').toJSON()
      }
      const style = Object.assign({}, this.book.style, {baby})
      await this.$parent.saveDesign({style})
      this.baby.enable = true
      this.$alert.success('宝贝生日信息已保存')
    },
    async selectTemplate(item) {
      if (item.codeName !== this.book.type) {
        this.saving = true

        this.book.type = item.codeName

        const data = {
          type: item.codeName,
          title: this.book.title,
          author: this.book.author,
          style: this.book.style || {},
          cover: this.book.cover,
          preface: this.book.preface || '',
          acknowledgement: this.book.acknowledgement || ''
        }

        if (item.codeName.endsWith('child')) {
          data.style.title = 'child'
          data.style.preface = 'child'
          data.style.acknowledgement = 'child'
          if (data.style.insertPage !== 'blank') {
            data.style.insertPage = 'child'
          }
        } else {
          data.style.title = 'default'
          data.style.preface = 'default'
          data.style.acknowledgement = 'default'
        }

        try {
          await this.$parent.saveDesign(data)
        } finally {
          setTimeout(() => {
            this.saving = false
          }, 500)
        }
      }

      if (this.book.type === 'A4-1-child' && !this.baby.nickname) {
        this.$bvModal.show('baby')
      }

      if (this.canEnableComment && this.book.style.enableComment === undefined) {
        this.$bvModal.show('comment')
      }
    },
    async saveFontSize(value) {
      console.log(this.fontSize)
      this.saving = true
      const style = Object.assign({}, this.book.style, {fontSize: value})
      // 必须删除动态生成的字段，否则下次读取时会覆盖配置预设的值
      // 后期如果可单独调节某一部分的字号可将fontSize字段改为对象
      // 如 fontSize: {base: 'normal', title: 'max', subtitle: 'big'}
      delete style.text
      delete style.subtitle
      delete style.catalog
      delete style.title
      delete style.note
      try {
        await this.$parent.saveDesign({style})
      } finally {
        setTimeout(() => {
          this.saving = false
        }, 500)
      }
    },
    async saveAlign() {
      this.saving = true
      const style = Object.assign({}, this.book.style, {
        textStyle: this.textAlign,
        titleStyle: this.titleAlign,
        dateStyle: this.dateEnabled ? ['displayed', this.dateAlign].join(';') : ''
      })
      try {
        await this.$parent.saveDesign({style})
      } finally {
        setTimeout(() => {
          this.saving = false
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.template-design {
  .intro {
    margin-bottom: 1em;
    text-align: center;
  }

  .ribbon {
    border-radius: 4px;
    border: 2px solid $primary;
    padding: .5rem;
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    text-align: center;
    width: 8em;
    color: $primary;
    background-color: transparentize(#fff, 0.1);
    font-weight: bold;
    margin: auto;
  }

  .template-list {
    display: flex;
    flex-direction: row;
    text-align: center;

    .grid-item {
      position: relative;
      margin-top: 0;
      padding: 0 .5rem;
      min-width: 6rem;

      &[class*="A4"] {
        min-width: 5rem * 1.4;
      }

      .title {
        color: $text-muted;
      }
    }
  }
}
</style>
